@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Roboto";
    src: url("./fonts/roboto/Roboto-Regular.ttf");
  }
  @font-face {
    font-family: "RobotoBold";
    src: url("./fonts/roboto/Roboto-Bold.ttf");
  }
  @font-face {
    font-family: "RobotoSemiBold";
    src: url("./fonts/roboto/Roboto-Bold.ttf");
  }
  @font-face {
    font-family: "RobotoMedium";
    src: url("./fonts/roboto/Roboto-Medium.ttf");
  }

  @font-face {
    font-family: "NotoSansArabic";
    src: url("./fonts/arabic/static/NotoSansArabic/NotoSansArabic-Regular.ttf");
  }
  @font-face {
    font-family: "NotoSansArabicBold";
    src: url("./fonts/arabic/static/NotoSansArabic/NotoSansArabic-Bold.ttf");
  }
  @font-face {
    font-family: "NotoSansArabicSemiBold";
    src: url("./fonts/arabic/static/NotoSansArabic/NotoSansArabic-SemiBold.ttf");
  }
  img {
    border-radius: none;
  }

  h1 {
    font-family: "RobotoBold", "NotoSansArabicBold", serif;
  }
  h2,
  h3 {
    font-family: "RobotoSemiBold", "NotoSansArabicBold";
  }

  ::placeholder,
  input {
    font-family: "RobotoSemiBold", "NotoSansArabicBold", serif !important;
    font-size: small;
  }
  body {
    font-family: "Roboto", "NotoSansArabic", serif;
    scroll-margin-top: 4rem !important;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow-anchor: none !important;
    min-height: 100vh;
    color: #002a4d;
  }
  th,
  td,
  tr {
    padding: 15px;
    font-family: "RobotoSemiBold", serif;
    font-weight: normal;
    border-left: 3px;
    border-radius: 15px;
    text-align: center;
  }

  option,
  select {
    font-family: "RobotoSemiBold" !important;
    font-size: small;
  }

  .hotel-images {
    position: relative;
  }
  .hotel-images img {
    border-radius: 0px !important;
  }
  .images-mask {
    position: absolute;
    border-radius: 0px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 1) 98%
    );
    cursor: pointer;
  }

  .speech-bubble {
    background-image: url("../public/assets/speech-bubble.png");

    background-repeat: no-repeat;
    background-position: 50% 5px;
    background-size: 100%;
    padding: 12px;

    font-family: "RobotoBold", "NotoSansArabicBold", serif;
  }

  .page-background {
    background-image: url("../public/assets/sky.svg"),
      url("../public/assets/buildings.svg");
    background-repeat: no-repeat;
    background-position: 10% 10%, 50% 100%;
    background-size: 100%, 70%;
  }
  .page-background-green {
    background-image: url("../public/assets/sky-green.png"),
      url("../public/assets/buildings-green.png");
    background-repeat: no-repeat;
    background-position: 10% 10%, 50% 100%;
    background-size: 100%, 70%;
  }
  input:focus {
    outline: none !important;
  }
  .user-dropdown ul,
  li {
    font-family: "RobotoSemiBold", "NotoSansArabicBold", serif;
  }
}
/* hide scrollbar for Chrome, Safari, and Opera */
div::-webkit-scrollbar {
  display: none;
}

/* hide scrollbar for Firefox */
div {
  scrollbar-width: none;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

* {
  scrollbar-width: thin;
  border-radius: 14px;
}
::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
  background: #a8a8a8;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.activeTab {
  padding-top: 0.75rem /* 12px */;
  padding-bottom: 0.75rem /* 12px */;
}

/* Price range slider*/

.slider {
  position: relative;
  width: auto;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #0081df;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #3f3f3f;
  font-size: 15px;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -12px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 22%;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

.carousel-image {
  direction: initial;
}
.carousel-arrow-left {
  left: 0;
  transform: translate(-50%, -50%);
}

@keyframes animateWidth {
  0% {
    width: 0%;
  }
  100% {
    width: 50%;
  }
}

.animateProgressBar {
  animation: animateWidth 2s ease-in-out;
}

.animate-border {
  border: 1px solid #f1f5f7;
}
.animate-border:hover {
  box-shadow: 0 0 0 10px rgba(0, 129, 223, 0.4);
  animation: border-pulse 2s infinite;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 129, 223, 0.4);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 129, 223, 0);
  }
}

.marker-label {
  background-color: #35678b;
}

.leaflet-popup {
  bottom: -32px !important;
  left: -52px !important;
  height: 0px;
  width: 0px;
  top: -18px;
  position: relative;
}

.leaflet-popup-close-button,
.leaflet-popup-tip {
  display: none;
}

.leaflet-popup-content-wrapper {
  background-color: transparent !important;
}

.map-price-tooltip {
  background-color: #35678b !important;
  color: white !important;
  border-radius: 8px !important;
  cursor: pointer !important;
}

.leaflet-control-attribution {
  display: none;
}
.ReactModal__Content,
.ReactModal__Content button {
  z-index: 999999 !important;
}

.ar .rdrNextPrevButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ar .rdrNextPrevButton i {
  margin: 0;
  rotate: 180deg;
}